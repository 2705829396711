<template>
  <div class="lianluo" style="height: 100%">
    <van-collapse v-model="activeNames" accordion >
      <van-collapse-item title="1. 办公室工作职责" name="1">
        <div class="content">
          <h3 class="title">办公室工作职责</h3>
          <h4>一、工作职责</h4>
          <p>
            1.
            推动上级决策部署和产业集聚区领导同志重要讲话、指示批示精神贯彻落实；
          </p>
          <p>
            2.
            协助党工委、管委会领导同志处理日常事务，负责产业集聚区日常事务的综合协调工作；
          </p>
          <p>
            3. 负责产业集聚区综合材料、法规、调研、信息、会务、值班通联等工作；
          </p>
          <p>4. 负责产业集聚区文件收发、档案、保密等工作；</p>
          <p>5. 负责内部管理、接待、车辆管理、安全、保卫、后勤保障等工作；</p>
          <p>6. 负责督查、目标管理、绩效考核等工作；</p>
          <p>7. 负责人事、机构编制和劳资工作；</p>
          <p>8. 负责承办产业集聚区管委会交办的其他工作。</p>
          <div class="iphone">联系电话:<a href="tel:66912192"><span>66912192</span></a></div>
        </div>
      </van-collapse-item>
      <van-collapse-item title="2. 党建科工作职责" name="2"
        ><div class="content">
          <h3 class="title">党建科工作职责</h3>
          <h4>一、工作职责</h4>
          <p>
            1.
            贯彻落实上级关于党的组织建设、宣传思想文化、意识形态、统一战线、民族宗教等方面法律法规、决策部署；
          </p>
          <p>
            2.
            负责管委会党建、党风廉政建设、宣传、精神文明建设、统战、工会、共青团、妇联、侨联、台联、工商联、民族宗教等工作；
          </p>
          <p>3. 负责承办产业集聚区管委会交办的其他工作。</p>
          <!-- <div class="iphone">联系电话:<a href="tel:0379-67866558"><span>0379-67866558</span></a></div> -->
        </div></van-collapse-item
      >
      <van-collapse-item title="3. 改革发展科工作职责" name="3"
        ><div class="content">
          <h3 class="title">改革发展科工作职责</h3>
          <h4>一、工作职责</h4>
          <p>
            1.
            贯彻落实上级部门关于产业发展、财政金融、统计、工信科技及能源等政策方针，拟定产业集聚区相应政策并组织实施；
          </p>
          <p>
            2.
            制定产业集聚区财政税收、发展规划、重大工业产业发展战略、经济计划等方案；
          </p>
          <p>3. 监测调控产业集聚区经济运行指标，服务企业生产、项目建设；</p>
          <p>4. 谋划实施三大改造、科技创新、战略性新兴产业等领域产业链；</p>
          <p>5. 负责承办产业集聚区管委会交办的其他工作。</p>
          <div class="iphone">联系电话:<a href="tel:67867366"><span>67867366</span></a></div>
        </div></van-collapse-item
      >
      <van-collapse-item title="4. 生态环境保护和安全监管科工作职责" name="4">
        <div class="content">
          <h3 class="title">生态环境保护和安全监管科工作职责</h3>
          <h4>一、工作职责</h4>
          <p>1. 宣传贯彻生态环境、安全生产方面法律法规；</p>
          <p>2. 协助企业办理环保、安全方面手续；</p>
          <p>3. 排查环保、安全隐患，督促企业整改落实；</p>
          <p>4. 完成上级交办的其他工作；</p>
          <p>5. 负责承办产业集聚区管委会交办的其他工作。</p>
          <!-- <div class="iphone">联系电话:<a href="tel:0379-67866558"><span>0379-67866558</span></a></div> -->
        </div>
      </van-collapse-item>
      <van-collapse-item title="5. 社会事业科工作职责" name="5">
        <div class="content">
          <h3 class="title">社会事业科工作职责</h3>
          <h4>一、工作职责</h4>
          <p>
            1. 负责编制产业集聚区年度建设发展计划和中长期发展规划并组织实施；
          </p>
          <p>
            2.
            负责产业集聚区国土空间规划和测绘管理并监督实施，组织实施经批准的控制性详细规划；
          </p>
          <p>3. 负责产业集聚区内基础设施和公共服务设施建设；</p>
          <p>4. 负责落实项目的水、电、气、通讯等配套设施；</p>
          <p>5. 负责征地、拆迁、居民安置协调等工作；</p>
          <p>6. 负责产业集聚区内产业项目的选址、占地、附属物清偿工作；</p>
          <p>7. 负责管理产业集聚区内的社会事务；</p>
          <p>8. 负责承办产业集聚区管委会交办的其他工作。</p>
          <div class="iphone">联系电话:<a href="tel:67862766"><span>67862766</span></a></div>
        </div>
      </van-collapse-item>
      <van-collapse-item title="6. 招商引智和营商环境服务中心工作职责" name="6">
        <div class="content">
          <h3 class="title"></h3>
          <h4>一、工作职责</h4>
          <p>1. 负责制定项目招商计划，投资项目的洽谈签约；</p>
          <p>2. 负责承接产业转移和经济协作；</p>
          <p>3. 负责配合企业做好项目建设各项审批备案等；</p>
          <p>4. 负责组织企业参加各类经贸交流活动等；</p>
          <p>5. 负责人力资源引进、开发等；</p>
          <p>
            6.
            负责配合企业做好项目建设各项审批备案等，对项目建设、生产经营等提供跟踪服务，协调各项惠企政策落实，维护企业利益等；
          </p>
          <p>7. 负责承办产业集聚区管委会交办的其他工作。</p>
          <!-- <div class="iphone">联系电话:<a href="tel:0379-67866558"><span>0379-67866558</span></a></div> -->
        </div>
      </van-collapse-item>
      <van-collapse-item title="7. 城市管理服务中心工作职责" name="7">
        <div class="content">
          <h3 class="title">城市管理服务中心工作职责</h3>
          <h4>一、城市管理方面</h4>
          <p>
            1.
            贯彻国家有关城市管理方面的法律、法规和方针、政策，结合产业集聚区实际拟定产业集聚区城市管理的行政措施和规范性文件并组织实施；
          </p>
          <p>
            2.
            研究制定产业集聚区市容环境卫生发展规划和年度、阶段性工作计划，组织、指导产业集聚区内各企业、单位开展城市管理工作；
          </p>
          <p>
            3.
            组织开展城市管理方面法律法规和市容环境卫生管理知识的宣传教育，增强产业集聚区内的市容环境卫生意识和法制意识；
          </p>
          <p>
            4.
            负责对产业集聚区内各企业、各单位户外广告、宣传品设置的管理，负责产业集聚区内市容美化、灯光亮化的组织、协调和督促检查，负责对产业集聚区内道路（广场、空地）临时占用的审批与管理；
          </p>
          <p>
            5.
            组织开展产业集聚区内城市管理的综合整治和各项专项整治活动，指导开展群众性城市管理基础工作并定期组织检查、评比活动；
          </p>
          <p>
            6.
            参与产业集聚区内主干道两侧和公共场所搭建建筑物、构筑物或者其他设施规划的审核，参与产业集聚区内市场建设规划项目的审核，参与产业集聚区内道路两侧和公共场所临时停车点设置的审核，参与产业集聚区内机动车辆清洗点设置的审核，参与产业集聚区内城市公交线路的新辟、调整及站台新建、扩建、改建的审核，参与产业集聚区内主干道两侧新建项目竣工验收和新建住宅小区的综合性验收等；
          </p>
          <p>
            7. 负责产业集聚区内防汛设施建设与维护，确保产业集聚区内不发生内涝；
          </p>
          <p>8. 组织环卫队对产业集聚区内主要道路的清洗保洁工作；</p>
          <p>
            9. 组织市政园林队对产业集聚区内园林绿化和市政设施的管理和维护工作；
          </p>
          <p>10. 负责承办产业集聚区管委会交办的其他工作。</p>
          <h4>一、城市管理行政执法方面</h4>
          <p>
            1.
            行使产业集聚区内市容市貌、环境卫生、渣土运输管理方面的法律、法规、规章规定的行政处罚权，强制拆除不符合城市容貌标准、环境卫生标准的建筑物或者设施；
          </p>
          <p>2. 行使城市规划管理方面的法律、法规、规章规定的行政处罚权；</p>
          <p>3. 行使城市绿化管理方面的法律、法规、规章规定的行政处罚权；</p>
          <p>4. 行使市政管理方面的法律、法规、规章规定的行政处罚权；</p>
          <p>
            5.
            行使工商行政管理方面的法律、法规、规章规定的对无照商贩的行政处罚权；
          </p>
          <p>6. 履行省、市、区人民政府规定的其他职责。</p>
          <div class="iphone">联系电话:<a href="tel:67867366"><span>67867366</span></a></div>
        </div>
      </van-collapse-item>
      <van-collapse-item title="8. 政务服务中心工作职责" name="8">
        <div class="content">
          <h3 class="title"></h3>
          <h4>一、工作职责</h4>
          <p>
            1.
            负责区政府授权或相关职能部门委托、下放的行政许可、行政审批等公共事项的受理、审核和审批；
          </p>
          <p>2. 负责产业集聚区管委会职权内事项的审核、审批、证照办理；</p>
          <p>3. 负责牵头协调相关审批事宜的联审联办；</p>
          <p>4. 负责对进驻窗口及工作人员的日常管理和考核；</p>
          <p>5. 负责对中心窗口工作人员服务质量的投诉受理及处理；</p>
          <p>
            6.
            负责研究政务服务的新情况、新经验，创新政务服务方式和工作机制，提高政务服务效能和质量；
          </p>
          <p>7. 负责承办产业集聚区管委会交办的其他工作。</p>
          <div class="iphone">联系电话:<a href="tel:67866558"><span>67866558</span></a></div>
        </div>
      </van-collapse-item>
    </van-collapse>
  </div>
</template>
<script>
import { WeGetAppletInfo } from "@/api/index";
export default {
  data() {
    return {
      active: 4,
      appInfo: {},
      activeNames:[1],
    };
  },
  mounted() {
    this.getAppInfo();
  },
  methods: {
    // 小程序展示数据
    getAppInfo() {
      WeGetAppletInfo().then((res) => {
        var list = res.data;
        if (list.code == 0) {
          this.appInfo = list.data;
        }
      });
    },
  },
};
</script>
<style scoped>
.content .title {
  text-align: center;
}
.content h4 {
  padding: 0px 15px;
  margin: 8px 0px;
}
.content p {
  padding: 0px 15px;
  margin: 5px 0px;
  text-indent: 2em;
  line-height: 35px;
}
.iphone {
  color: #333;
  margin-left: 15px;
}
.iphone span {
  color: #2196f3;
}
</style>